import EventBus from '@/util/EventBus.js'
import Button360Dialog from '@/components/Button360Dialog/Button360Dialog.vue'
import WhatsappUserService from '@/services/whatsapp-user-service'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import FacebookLoginButton from '@/components/ButtonMeta/FacebookLoginButton.vue'

export default {
  name: 'WhatsAppChannel',
  components: {FacebookLoginButton, Button360Dialog, HeaderTopDashboard },
  data () {
    return {
      e1: 1,
      hasFacebookAccount: false,
      hasPhoneRequirement: false,
      user: this.$store.getters['auth/getUser'],
    }
  },
  created () {},
  mounted () {},
  computed: {
    partner () {
      return JSON.stringify({
        user_id: this.user.vid,
        username: this.user.username,
      })
    },
  },
  methods: {
    showSupportForm () {
        EventBus.$emit('showSupportForm')
    },
    callback360dialog (callbackObject) {
      console.log(callbackObject)
      if (!callbackObject.partner.length || !callbackObject.client?.length) {
        return
      }
      const params = {
        partnerId: callbackObject.partner,
        clientId: callbackObject.client,
        channels: callbackObject.channels,
        revokedChannels: callbackObject.revokedChannels,
      }

      WhatsappUserService.addClient(params)
        .then(
          (result) => {
            EventBus.$emit('showAlert', 'success', this.$t('Se han asociado los canales.'))
          },
          () => {
            EventBus.$emit('showAlert', 'danger', this.$t('Ocurrió un error al asociar los canales.'))
          },
        )
    },
  },
}
