import whatsappRegisterService from '../../services/whatsapp-register.service'
import EventBus from '../../util/EventBus'

export default {
    name: 'FacebookLoginButton',
    props: {
        buttonText: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            fbAppId: '191512336190098',
            fbGraphVersion: 'v22.0',
            configId: '1317100072330198',
            user: this.$store.getters['auth/getUser'] ?? JSON.parse(localStorage.getItem('user')),
        }
    },
    mounted () {
        this.loadFacebookSDK()
        window.addEventListener('message', this.handleMessageEvent)
    },
    beforeUnmount () {
        window.removeEventListener('message', this.handleMessageEvent)
    },
    methods: {
        loadFacebookSDK () {
            if (document.getElementById('facebook-jssdk')) return

            const script = document.createElement('script')
            script.id = 'facebook-jssdk'
            script.async = true
            script.defer = true
            script.crossOrigin = 'anonymous'
            script.src = 'https://connect.facebook.net/en_US/sdk.js'
            script.onload = this.initFacebookSDK

            document.head.appendChild(script)
        },
        initFacebookSDK () {
            window.fbAsyncInit = () => {
                FB.init({
                    appId: this.fbAppId,
                    autoLogAppEvents: true,
                    xfbml: true,
                    version: this.fbGraphVersion,
                })
            }
        },
        handleMessageEvent (event) {
            if (event.origin !== 'https://www.facebook.com' && event.origin !== 'https://web.facebook.com') return
            try {
                const data = JSON.parse(event.data)
                if (data.type === 'WA_EMBEDDED_SIGNUP') {
                    console.log('message event:', data) // remove after testing
                    whatsappRegisterService.registerUser({
                        type: 'REGISTER',
                        userVsmscId: this.user.vid,
                        name: this.user.username,
                        wabaId: data.data.waba_id,
                        phoneId: data.data.phone_number_id,
                        msisdn: data.msisdn,
                        businessId: data.data.business_id,
                        partnerId: 4,
                    })
                    .then((result) => {
                        console.log('registerUser result:', result)
                    })
                }
            } catch {
                console.log('message event:', event.data) // remove after testing
                EventBus.$emit('showAlert', 'danger', this.$t('Se ha abandonado el proceso de registro integrado').toString())
            }
        },
        fbLoginCallback (response) {
            if (response.authResponse) {
                console.log('response:', response.authResponse.code) // remove after testing
                whatsappRegisterService.registerLog({
                    type: 'REGISTER_CODE',
                    userVsmscId: this.user.vid,
                    name: this.user.username,
                    partnerId: 4,
                    code: response.authResponse.code,
                })
            } else {
                console.log('response:', response) // remove after testing
                // your code goes here
            }
        },
        launchWhatsAppSignup () {
            FB.login(this.fbLoginCallback, {
                config_id: this.configId,
                response_type: 'code',
                override_default_response_type: true,
                extras: {
                    setup: {},
                    featureType: '',
                    sessionInfoVersion: '3',
                },
            })
        },
    },
}
