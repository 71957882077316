import { WhatsappWebhook } from './whatsapp-webhook.service'

const PATH = 'whatsapp/link-mobility/'

class WhatsappRegisterService extends WhatsappWebhook {
    registerUser (params = {}) {
        return this.postResult(PATH + 'register', params)
    }

    registerLog (params = {}) {
        return this.postResult(PATH + 'register-code', params)
    }
}

export default new WhatsappRegisterService()
